import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Home.css";
import HomeCarousel from "../components/HomeCarousel";
import Categories from "../components/Categories";
import LatestProducts from "../components/LatestProducts";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const heroTitleRef = useRef(null);
  const heroSubtitleRef = useRef(null);
  const categoriesRef = useRef(null);
  const latestProductsRef = useRef(null);

  useEffect(() => {
    // Hero section title animation (fade in and move up)
    gsap.fromTo(
      heroTitleRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power2.out",
      }
    );

    // Hero subtitle animation (delay and fade in)
    gsap.fromTo(
      heroSubtitleRef.current,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.out",
      }
    );

    // Categories pop effect (scale up) when scrolling into view
    gsap.fromTo(
      categoriesRef.current,
      { scale: 0.8, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        duration: 0.6,
        ease: "power2.out",
        scrollTrigger: {
          trigger: categoriesRef.current,
          start: "top 85%",
          end: "bottom 60%",
          scrub: false,
        },
      }
    );

    // Simple fade-in for Latest Products section
    gsap.fromTo(
      latestProductsRef.current,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.8,
        ease: "power2.out",
        scrollTrigger: {
          trigger: latestProductsRef.current,
          start: "top 90%",
          end: "bottom 80%",
          scrub: false,
        },
      }
    );
  }, []);

  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <HomeCarousel ref={heroTitleRef} />
      </section>

      {/* Categories Section with pop effect */}
      <section ref={categoriesRef} className="categories-section">
        <Categories />
      </section>

      {/* Latest Products Section with simple fade-in */}
      <section ref={latestProductsRef} className="latest-products-section">
        <LatestProducts />
      </section>
    </div>
  );
};

export default Home;
