import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { gsap } from "gsap";
import "./HomeCarousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Example images (replace with your own images)
import slide1 from "../images/devicesbg.png";
import slide2 from "../images/excitedbg.png";
import slide3 from "../images/servicebg.png";

const HomeCarousel = () => {
  const sliderRef = useRef(null);
  const slidesRef = useRef([]);
  const imagesRef = useRef([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Fancy slow transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    arrows: true,
    beforeChange: (oldIndex, newIndex) => handleSlideChange(newIndex),
  };

  // Handle the slide change with GSAP animation
  const handleSlideChange = (newIndex) => {
    gsap.fromTo(
      slidesRef.current[newIndex],
      { opacity: 0, scale: 0.8 },
      {
        opacity: 1,
        scale: 1,
        duration: 1.5,
        ease: "power4.out",
      }
    );

    // Animate the image of the current slide
    gsap.fromTo(
      imagesRef.current[newIndex],
      { x: -100, opacity: 0 }, // Start off-screen to the left
      { x: 0, opacity: 1, duration: 1.5, ease: "power4.out" }
    );
  };

  useEffect(() => {
    // Trigger animation for the first slide
    handleSlideChange(0);
  }, []);

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {/* Slide 1 */}
        <div
          ref={(el) => (slidesRef.current[0] = el)}
          className="carousel-slide"
        >
          <div className="carousel-content">
            <img
              ref={(el) => (imagesRef.current[0] = el)}
              src={slide1}
              alt="Slide 1"
              className="carousel-image"
            />
            <div className="carousel-text">
              <h2>
                {" "}
                <span className="cycle-colors">Amazing</span> Quality
              </h2>
              <p> All Our Products are carefully inspected for great quality</p>
              <Link to="/about" className="home-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div
          ref={(el) => (slidesRef.current[1] = el)}
          className="carousel-slide"
        >
          <div className="carousel-content">
            <img
              ref={(el) => (imagesRef.current[1] = el)}
              src={slide2}
              alt="Slide 2"
              className="carousel-image"
            />
            <div className="carousel-text">
              <h2>Affordable</h2>
              <p>
                {" "}
                Great prices and deals, especially for returning customers!
              </p>
              <Link to="/about" className="home-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        {/* Slide 3 */}
        <div
          ref={(el) => (slidesRef.current[2] = el)}
          className="carousel-slide"
        >
          <div className="carousel-content">
            <img
              ref={(el) => (imagesRef.current[2] = el)}
              src={slide3}
              alt="Slide 3"
              className="carousel-image"
            />
            <div className="carousel-text">
              <h2>Excellent Customer Service</h2>
              <p>We aim to please. Feel free to contact us</p>
              <Link to="/about" className="home-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HomeCarousel;
