import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Input,
  Divider,
  Alert,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useGetProductDetailsQuery } from "../redux/api/productsApi";
import Loader from "../components/Loader"; // Import the loader
import toast from "react-hot-toast";
import StarRatings from "react-star-ratings";
import "./ProductDetails.css";

const ProductDetails = () => {
  const params = useParams();
  const { data, isLoading, error, isError } = useGetProductDetailsQuery(
    params?.id
  );
  const product = data?.product;
  const [activeImg, setActiveImg] = useState("");

  useEffect(() => {
    setActiveImg(
      product?.images[0]
        ? product.images[0]?.url
        : "/images/default_product.png"
    );
  }, [product]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
    }
  }, [isError]);

  if (isLoading) return <Loader />;
  return (
    <div className="productDetails">
      <Grid
        container
        spacing={4}
        justifyContent="space-around"
        alignItems="flex-start"
      >
        {/* Product Image Section */}
        <Grid item xs={12} lg={5}>
          <Box p={3}>
            <img
              src={activeImg}
              alt="Product"
              width="340"
              height="390"
              style={{ display: "block", width: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Thumbnail Section */}
          <Grid container spacing={2} justifyContent="flex-start" mt={5}>
            {product?.images?.map((img) => (
              <Grid item>
                <IconButton>
                  <img
                    src={img?.url}
                    alt={img?.url}
                    onClick={(e) => setActiveImg(img.url)}
                    width="100"
                    height="100"
                    style={{
                      display: "block",
                      border: `1px solid ${
                        img.url === activeImg ? "yellow" : "#ccc"
                      }`,
                      borderRadius: "8px",
                      padding: "5px",
                    }}
                  />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Product Details Section */}
        <Grid item xs={12} lg={5} mt={{ xs: 3, lg: 5 }}>
          <Typography variant="h4" gutterBottom>
            {product?.name}
          </Typography>
          <Typography variant="body1" id="product_id" gutterBottom>
            Product #{product?._id}
          </Typography>
          <Divider />

          {/* Star Ratings */}
          <Box display="flex" alignItems="center" mt={2}>
            <Box display="flex" color="gold">
              <StarRatings
                rating={product?.ratings}
                starRatedColor="#ffb829"
                starDimension="24px"
                starSpacing="1px"
                numberOfStars={5}
                name="rating"
              />
            </Box>
            <Typography id="no-of-reviews" variant="body2" sx={{ pl: 2 }}>
              ({product?.numOfReviews} Reviews)
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />

          {/* Product Price */}
          <Typography variant="h5" id="product_price">
            ₦{product?.price}
          </Typography>

          {/* Stock Counter */}
          <Box display="flex" alignItems="center" mt={2}>
            <Button variant="contained" color="error" className="minus">
              -
            </Button>
            <Input
              value={1}
              inputProps={{
                readOnly: true,
                style: { textAlign: "center", width: "40px" },
              }}
              className="count"
              sx={{ mx: 1 }}
            />
            <Button variant="contained" color="primary" className="plus">
              +
            </Button>

            {/* Add to Cart Button */}
            <Button
              variant="contained"
              color="primary"
              id="cart_btn"
              sx={{ ml: 4 }}
              disabled
            >
              Add to Cart
            </Button>
          </Box>
          <Divider sx={{ my: 2 }} />

          {/* Stock Status */}
          <Typography variant="body1" mt={2}>
            Status:{" "}
            <span
              id="stock_status"
              style={{ color: product?.stock > 0 ? "green" : "red" }}
            >
              {product?.stock > 0 ? "In Stock" : "Out of Stock"}
            </span>
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Product Description */}
          <Typography variant="h6" gutterBottom>
            Description:
          </Typography>
          <Typography variant="body2" gutterBottom>
            {product?.description}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Product Seller */}
          <Typography id="product_seller" gutterBottom>
            Sold by: <strong>{product?.seller}</strong>
          </Typography>

          {/* Alert for Login */}
          <Alert severity="warning">Login to post your review.</Alert>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductDetails;
