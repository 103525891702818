import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import anime from "animejs";
import "./Categories.css";
import PhoneImg from "../images/phones.jpg";
import PhoneImg2 from "../images/phones2.jpg";
import LaptopImg from "../images/laptops.jpg";
import TabletImg from "../images/tablets.jpg";
import TabletImg2 from "../images/tablets2.jpg";
import AudioImg from "../images/audio.jpg";
import AudioImg2 from "../images/audio2.jpg";
import AccImg from "../images/accessories.jpg";

const Categories = () => {
  const categories = [
    { name: "Phones", img: PhoneImg, img2: PhoneImg2 },
    { name: "Laptops", img: LaptopImg, img2: LaptopImg },
    { name: "Tablets", img: TabletImg, img2: TabletImg2 },
    { name: "Audio", img: AudioImg, img2: AudioImg2 },
    { name: "Accessories", img: AccImg, img2: AccImg },
  ];

  useEffect(() => {
    anime({
      targets: ".category-box",
      translateY: [-10, 0],
      opacity: [0, 1],
      delay: anime.stagger(200), // Stagger the animations
      easing: "easeOutQuad",
      duration: 800,
    });
  }, []);

  const handleHover = (e, index) => {
    anime({
      targets: `.category-box-${index}`,
      scale: 1.1,
      boxShadow: `0 8px 20px rgba(0, 0, 0, 0.3)`,
      duration: 300,
      easing: "easeOutQuad",
    });
  };

  const handleMouseOut = (e, index) => {
    anime({
      targets: `.category-box-${index}`,
      scale: 1,
      boxShadow: `0 4px 12px rgba(0, 0, 0, 0.1)`,
      duration: 300,
      easing: "easeOutQuad",
    });
  };

  return (
    <div className="category-mainbox">
      <Typography variant="h3" color="#fff" gutterBottom>
        Categories
      </Typography>
      <Box className="categories-container">
        {categories.map((category, index) => (
          <Box
            key={index}
            className={`category-box category-box-${index}`}
            onMouseEnter={(e) => handleHover(e, index)}
            onMouseLeave={(e) => handleMouseOut(e, index)}
            sx={{
              backgroundImage: {
                xs: `url(${category.img})`,
                md: `url(${category.img2})`,
              },
              backgroundSize: "cover",
            }}
          >
            <Typography variant="h4" className="category-text">
              {category.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Categories;
