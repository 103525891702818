import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.css";
import Logo from "../images/logo.png";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="logo-wrapper">
        {/* This is the circular progress loader */}
        <CircularProgress size={180} thickness={4} />
        {/* This is the pulsing logo */}
        <div className="pulsing-logo">
          <img src={Logo} alt="Skicom Logo" />
        </div>
      </div>
      <div className="loading-text">Loading...</div>
    </div>
  );
};

export default Loader;
