import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import { FaSearch } from "react-icons/fa";

const Search = () => {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword?.trim()) {
      navigate(`/?keyword=${keyword}`);
    } else {
      navigate(`/`);
    }
  };

  return (
    <div>
      <form onSubmit={submitHandler} style={{ borderRadius: "60px" }}>
        <TextField
          fullWidth
          sx={{ padding: "0 10px" }}
          variant="standard"
          name="keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Enter Product Name"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end" sx={{ padding: "10px" }}>
                <IconButton type="submit">
                  <FaSearch />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
};

export default Search;
